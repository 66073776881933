<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="10" :sm="10" :md="6" :lg="4" :xl="3">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入用户ID/昵称/姓名/手机号码"> </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
          <el-button type="primary" class="margin-left20" @click="handleSearch">搜索</el-button>
          <el-button type="danger" class="margin-left20" @click="handleAdd">添加</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="userId" label="用户ID" width="80" align="center"></el-table-column>
      <el-table-column label="用户头像" min-width="200" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.profilePic" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="用户昵称" min-width="200" align="center"></el-table-column>
      <el-table-column prop="trueName" label="姓名" min-width="120" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="120" align="center"></el-table-column>
      <el-table-column prop="balance" label="余额" min-width="120" align="center"></el-table-column>
      <el-table-column prop="hadSpend" label="已消费" min-width="120" align="center"></el-table-column>
      <el-table-column prop="goldenVal" label="金币" min-width="120" align="center"></el-table-column>
      <el-table-column prop="userGoldenVal" label="已使用金币" min-width="120" align="center"></el-table-column>
      <el-table-column prop="createTime" label="添加时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160" align="center">
        <template slot-scope="scope">
          <el-button @click="handleCancel(scope.row)" size="small" type="danger">取消资格</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
    <addDialog :isshowDialog="isshowDialog" @save="save" @close="close" />
  </div>
</template>

<script>
import testImg from '@/assets/images/test.jpg'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { userOfficialList, userOfficialAdd, userGoldenCancel } from '@/api/UserController'
import addDialog from './component/addDialog'
export default {
  components: {
    TitleBarBaseVue,
    addDialog
  },
  data() {
    return {
      tableList: [],
      params: {
        keyWords: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      isshowDialog: false
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    handleAdd() {
      this.isshowDialog = true
    },
    async save(userId) {
      let [err, res] = await userOfficialAdd({ userId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '添加失败')
      } else {
        this.isshowDialog = false
        this.getUserList()
      }
    },
    close() {
      this.isshowDialog = false
    },
    async handleCancel(row) {
      console.log(row.userId)
      await this.$repeatedConfirm()
      let [err, res] = await userGoldenCancel({ userId: row.userId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '取消失败')
      }
      console.log(res)
      this.$message.success(res.msg || '取消成功')
      this.getUserList()
    },
    changeParams(v) {
      !v && this.handleSearch()
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },
    handleSearch() {
      console.log(this.params)
      this.getUserList()
    },
    async getUserList() {
      let [err, res] = await userOfficialList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.getUserList()
    }
  },
  filters: {
    filterUserType(v) {
      return userType[v]
    }
  }
}
</script>
